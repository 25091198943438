import { Footer } from "../footer/Footer"
import { Header_1 } from "../header_1/Header_1"
import { SearchList } from "./SearchList"

export const SearchContainer = () => {


  return (
    <>
      <Header_1 />
      <SearchList />

      <Footer /></>
  )
}