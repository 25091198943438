import { Bottom_navbar, Header_1, Header_2 } from '../../components';
import './header.scss';

export const Header = () => {


  return (
    <>
      <Header_1 />
      <Header_2 />
      <Bottom_navbar />
    </>

  )
}

